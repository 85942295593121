<template>
  <div>
    <info-modal v-model="modal.show" :title="modal.message" @close="closeModal">
      <v-btn v-if="modal.info" color="primary" block @click="closeModal">{{ $t('tasks.back_to_tasks') }}</v-btn>
    </info-modal>
    <base-dialog ref="deleteDialog" :title="$t('task.delete_message')" :text="$t('task.delete_text')" />
    <tasks-entity-header
      class="mb-10"
      :title="taskId"
      :back-route="backRoute"
      :edit-route="editRoute"
      :can-update="canUpdate"
      :status="task.status"
      :deadline-date="formatDate(task.deadlineDate)"
      :is-overdue="task.isOverdue"
      :for-me="isForMe"
      @change-status="changeStatus({ newStatus: $event, id })"
      @delete-task="deleteTask(id)"
    />

    <div v-if="isLoading" class="d-flex justify-center primary--text">
      <v-progress-circular indeterminate />
    </div>
    <view-builder
      v-else-if="isRequestIssue"
      :schema-list="[$options.schemaWithTemplate, schemaRequestForm]"
      :data="task"
      is-tabs
      :tabs="tabs"
      :active-tab="tab"
      @change-tab="changeTab"
    />
    <view-builder v-else :schema="$options.schema" :data="task" />
    <task-mobile-status-buttons
      v-if="media.isMobile && (canUpdate || isForMe)"
      :status="task.status"
      :task-id="id"
      sticky
      @change-status="changeStatus"
    />
    <task-modal-change-status
      v-model="dataChangeStatus.isShowChangeStatus"
      :new-status="dataChangeStatus.newStatus"
      :task-id="dataChangeStatus.id"
      @success-change="showStatusChangeSuccessNotice"
      @fail-change="showStatusChangeFailNotice"
    />
  </div>
</template>

<script>
// Models
import { schema, schemaWithTemplate } from '@/schemas/task.view.schema';
import TaskModalChangeStatus from '@/components/Tasks/ModalChangeStatus.vue';
import { createModelData } from '@/schemas/createModelData';
import * as schemaItemTypes from '@/schemas/schemaItemTypes';

// Services
import issuesService from '@/services/issues';
import analyticsService from '@/services/analytics';
import { translateDate } from '@/utils/dateFormatting';
import format from 'date-fns/format';

// Constants
import { TASKS, TASKS_EDIT } from '@/constants/routes';
import { TASK } from '@/constants/analyticsActions';
import { TAB_BASE_INFO, TAB_REQUEST_FORM } from '@/constants/tabs';
import * as subjects from '@/constants/subjects';
import { UPDATE } from '@/constants/actions';

// Components
import TasksEntityHeader from '@/components/Tasks/EntityHeader.vue';
import TaskMobileStatusButtons from '@/components/Tasks/TaskMobileStatusButtons.vue';
import InfoModal from '@/components/InfoModal.vue';
import BaseDialog from '@/components/BaseDialog.vue';
import ViewBuilder from '@/components/schema/ViewBuilder.vue';

export default {
  name: 'TaskDetailed',

  components: {
    TasksEntityHeader,
    ViewBuilder,
    TaskMobileStatusButtons,
    TaskModalChangeStatus,
    InfoModal,
    BaseDialog,
  },
  inject: ['media'],

  props: {
    id: { type: Number, required: true },
    prevPage: { type: Number, default: 0 },
  },

  data() {
    return {
      isLoading: false,
      task: createModelData(schema),
      isRequestIssue: false,
      requestFields: [],
      dataChangeStatus: {
        isShowChangeStatus: false,
        newStatus: '',
        id: 0,
      },
      dataDeleteTask: {
        isShowModalDelete: false,
        taskId: 0,
      },
      modal: {
        show: false,
        message: '',
        info: true,
      },
      tab: 0,
      tabs: [
        {
          label: 'tab.base_info',
          tab: TAB_BASE_INFO,
        },
        {
          label: 'tab.request_form',
          tab: TAB_REQUEST_FORM,
        },
      ],
    };
  },

  computed: {
    canUpdate() {
      return this.$can(UPDATE, subjects.ISSUES);
    },

    isForMe() {
      return this.task.forMe;
    },

    backRoute() {
      return { name: TASKS, query: { page: this.prevPage || 1 } };
    },

    taskId() {
      if (this.isLoading) return '';
      return `№${this.id}`;
    },

    editRoute() {
      return { name: TASKS_EDIT, params: { id: this.id } };
    },

    schemaRequestForm() {
      return [
        {
          type: schemaItemTypes.ROW,
          children: this.requestFields.map(field => {
            let formItem = {};

            switch (field.fieldType) {
              case 'text':
                formItem = {
                  type: schemaItemTypes.STRING,
                  label: field.name,
                  prop: field.id,
                  size: 3,
                };
                break;
              case 'date_time':
                formItem = {
                  type: schemaItemTypes.STRING,
                  label: field.name,
                  prop: `date-time-${field.id}`,
                  size: 3,
                };
                break;
              case 'attachments':
                formItem = {
                  type: schemaItemTypes.FILE_LIST,
                  label: field.name,
                  showLabel: true,
                  prop: field.id,
                  size: 12,
                };
                break;
              case 'rowadder':
                formItem = {
                  type: schemaItemTypes.STRING,
                  label: field.name,
                  prop: field.id,
                  size: 6,
                };
                break;
              default:
                break;
            }

            return formItem;
          }),
        },
      ];
    },
  },

  async mounted() {
    analyticsService.track(TASK);
    this.isLoading = true;
    try {
      await this.initDetail();
    } finally {
      this.isLoading = false;
    }
  },

  methods: {
    async deleteTask(issueId) {
      const needDelete = await this.$refs.deleteDialog.open();

      if (needDelete) {
        this.isLoading = true;
        try {
          await issuesService.deleteIssueById(issueId);
          this.showModal(this.$t('tasks.success_delete_task'));
          this.$router.push(this.backRoute);
        } catch (e) {
          this.showModal(this.$t('tasks.fail_delete_task'));
        } finally {
          this.isLoading = false;
        }
      }
    },
    changeStatus({ newStatus, id }) {
      this.dataChangeStatus.isShowChangeStatus = true;
      this.dataChangeStatus.newStatus = newStatus;
      this.dataChangeStatus.id = id;
    },
    // async changeStatus({ newStatus, id }) {
    //   analyticsService.track(CHANGE_TASKS_STATUS);
    //   const infoStatus = {
    //     issueId: id,
    //     newStatus,
    //     isShowDetailsToClient: false,
    //   };
    //   this.loading = true;
    //   try {
    //     await issuesService.changeIssueStatus(infoStatus);
    //     this.showStatusChangeSuccessNotice();
    //   } catch (e) {
    //     this.showStatusChangeFailNotice();
    //   } finally {
    //     this.loading = false;
    //   }
    // },
    showStatusChangeSuccessNotice() {
      this.showModal(this.$t('tasks.success_change_status'));
      this.initDetail();
    },
    showStatusChangeFailNotice() {
      this.showModal(this.$t('tasks.fail_change_status'));
    },
    showModal(message) {
      this.modal.message = message;
      this.modal.show = true;
      this.modal.info = true;
    },
    closeModal() {
      this.modal.show = false;
      this.modal.message = '';
      this.modal.info = false;
    },
    async initDetail() {
      const issue = await issuesService.getIssueById(this.id);
      this.isRequestIssue = issue.isRequestIssue;

      if (this.isRequestIssue) {
        this.requestFields = issue.requestFormData.fields;
        this.task = {
          ...issue,
          client: this.getFullName(issue.client),
          clientType: issue.clientType ? this.$t(`client.${issue.clientType}`) : null,
          isPushInform: issue.isInformClientViaPush ? this.$t('yes') : this.$t('no'),
          project: this.getName(issue.project),
          building: this.getName(issue.building),
          unit: this.getName(issue.unit),
          room: issue.room && this.getName(issue.room),
          pendingRoles: issue.roles?.pending,
          inProgressRoles: issue.roles?.inProgress,
          completedRoles: issue.roles?.completed,
          // payments: {
          //   isPaymentRequired: issue.isPaymentRequired,
          //   paymentAmount: issue.isPaymentRequired ? issue.paymentAmount : null,
          //   paymentContractor: issue.isPaymentRequired ? issue.paymentContractor : null,
          //   paymentReceipt: issue.isPaymentRequired ? issue.paymentReceipt : null,
          // },
          statusesHistory: issue.statusesHistory.map(item => ({
            ...item,
            author: `${item.author.firstName} ${item.author.lastName}`,
            changeStatus: {
              comment: item.comment,
              oldStatus: item.oldStatus,
              newStatus: item.newStatus,
            },
          })),
        };

        for (let i = 0; i < this.requestFields.length; i += 1) {
          const field = this.requestFields[i];

          switch (field.fieldType) {
            case 'text':
              this.task[field.id] = field.value;
              break;
            case 'date_time':
              this.task[`date-time-${field.id}`] = this.normalizedDateTime(field.value);
              break;
            case 'attachments':
              this.task[field.id] = [{ id: 1, url: field.value }];
              break;
            case 'rowadder':
              this.task[field.id] = '';
              field.value.forEach((item, index) => {
                if (index) {
                  this.task[field.id] += `; ${item.join(', ')}`;
                } else {
                  this.task[field.id] += item.join(', ');
                }
              });
              break;
            default:
              break;
          }
        }
      } else {
        this.task = {
          ...issue,
          client: this.getFullName(issue.client),
          clientType: issue.clientType ? this.$t(`client.${issue.clientType}`) : null,
          isPushInform: issue.isInformClientViaPush ? this.$t('yes') : this.$t('no'),
          project: this.getName(issue.project),
          building: this.getName(issue.building),
          unit: this.getName(issue.unit),
          room: issue.room && this.getName(issue.room),
          pendingRoles: issue.roles?.pending,
          inProgressRoles: issue.roles?.inProgress,
          completedRoles: issue.roles?.completed,
          payments: {
            isPaymentRequired: issue.isPaymentRequired,
            paymentAmount: issue.isPaymentRequired ? issue.paymentAmount : null,
            paymentContractor: issue.isPaymentRequired ? issue.paymentContractor : null,
            paymentReceipt: issue.isPaymentRequired ? issue.paymentReceipt : null,
          },
          statusesHistory: issue.statusesHistory.map(item => ({
            ...item,
            author: `${item.author.firstName} ${item.author.lastName}`,
            changeStatus: {
              comment: item.comment,
              oldStatus: item.oldStatus,
              newStatus: item.newStatus,
            },
          })),
        };
      }
    },
    getName(value) {
      return value.name;
    },
    getFullName(value) {
      return value.id ? `${value.firstName} ${value.lastName}` : '';
    },
    getPerformers(value) {
      return value.map(item => item.firstName + item.lastName);
    },
    formatDate(date) {
      if (date) {
        const noFormatDate = new Date(date);
        return translateDate(format(noFormatDate, 'd L'));
      }
      return date;
    },
    normalizedDateTime(date) {
      return translateDate(format(new Date(date), 'd L HH:mm'));
    },
    changeTab(newTab) {
      this.tab = typeof newTab === 'number' ? newTab : this.tab + 1;
    },
  },

  schema,
  schemaWithTemplate,
};
</script>
